import Request from '@/http'
import { useAppStore } from '@/store/modules/app'

/*
根据当前部署域名获取租户信息, 如果是本地调试, 默认域名为yiautos
*/
export async function initTenantByDomain() {
	const appStore = useAppStore()
	const domain = window.location.host
	if (domain.includes('localhost')) {
		// 开发环境只有000000
		return appStore.setTenantId(process.env.VUE_APP_CONFIG_ENV === 'dev' ? '000000' : '000000') // 本地开发切换租户
	}
	try {
		const { code, data, msg } = await Request.post('/suf4-system-service/tenant/domain', {
			domainAddress: domain,
		})
		if (code === 200 && data) {
			appStore.setTenantId(data)
		} else {
			throw new Error(msg)
		}
	} catch (e) {
		console.log('获取租户id ==>', e)
	}
}
