<template>
	<div class="login-container">
		<div class="logo"></div>
		<div class="login-dot">
			<div class="dot-01"></div>
			<div class="dot-02"></div>
			<div class="dot-03"></div>
			<div class="dot-04"></div>
		</div>
		<div class="login-box">
			<div class="lb-left"></div>
			<div class="lb-right">
				<el-form
					v-show="isShowLogin"
					ref="loginFormRef"
					:model="loginForm"
					:rules="loginRules"
					class="login-form"
					label-position="left"
				>
					<h4 class="title padding-bottom-40">登录</h4>
					<el-form-item prop="username">
						<svg-icon icon-class="user"></svg-icon>
						<el-input
							ref="userNameRef"
							v-model="loginForm.username"
							placeholder="账号"
							maxlength="11"
							auto-complete="off"
						/>
					</el-form-item>
					<el-form-item prop="password">
						<svg-icon icon-class="password" />
						<el-input
							ref="passwordRef"
							v-model="loginForm.password"
							:type="isEyesOpened ? '' : 'password'"
							placeholder="密码"
							auto-complete="off"
							maxlength="30"
							@keyup.enter="handleLogin"
						/>
						<svg-icon
							v-show="isEyesOpened"
							icon-class="eyes_open"
							class="eyes"
							@click="isEyesOpened = false"
						/>
						<svg-icon
							v-show="!isEyesOpened"
							icon-class="eyes_close"
							class="eyes"
							@click="isEyesOpened = true"
						/>
					</el-form-item>
					<el-button type="text" @click="forgetPwd">忘记密码</el-button>
					<el-button
						class="login-btn"
						:loading="loading"
						type="primary"
						@click.prevent="handleLogin"
					>
						登录
					</el-button>
				</el-form>
				<reset-password
					v-show="!isShowLogin"
					ref="resetPasswordRef"
					:type="resetType"
					@reset-success="resetSuccess"
				></reset-password>
			</div>
		</div>
	</div>
</template>

<script>
import md5 from 'js-md5'
import { defineComponent, onMounted, reactive, watch, ref, toRefs, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { loginByUsername } from '@/api/user'
import { useUserStore } from '@/store/modules/user'
import { useAppStore } from '@/store/modules/app'
import { usePermissionStore } from '@/store/modules/permission'
import { useTagStore } from '@/store/modules/tag'
import { isValidUsername } from '@/utils/validate'
import ResetPassword from './components/ResetPassword'
import { initTenantByDomain } from '@/utils/tenant'

export default defineComponent({
	components: {
		ResetPassword,
	},
	setup() {
		const userNameRef = ref(null)
		const passwordRef = ref(null)
		const loginFormRef = ref(null)
		const resetPasswordRef = ref(null)

		const router = useRouter()
		const route = useRoute()
		const userStore = useUserStore()
		const appStore = useAppStore()
		const permissionStore = usePermissionStore()
		const tagStore = useTagStore()

		const $message = inject('$message')

		const state = reactive({
			isShowLogin: true,
			resetType: 'reset',
			isEyesOpened: false,
			loginForm: {
				tenantId: '',
				username: '',
				password: '',
				type: 'account',
			},
			loginRules: {
				username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
			},
			passwordType: 'password',
			loading: false,
			showDialog: false,
			capsTooltip: false,
			redirect: '',
			otherQuery: {},
		})
		const methods = reactive({
			validateUsername: (rule, value, callback) => {
				if (!isValidUsername(value)) {
					callback(new Error('账号不正确'))
				} else {
					callback()
				}
			},
			validatePassword: (rule, value, callback) => {
				if (value.length < 6) {
					callback(new Error('密码至少6位数字'))
				} else {
					callback()
				}
			},
			forgetPwd() {
				state.isShowLogin = false
				state.resetType = 'forget'
				resetPasswordRef.value.clearForm()
			},
			resetSuccess() {
				state.isShowLogin = true
				state.loginForm.password = ''
			},
			handleLogin: () => {
				loginFormRef.value.validate(async valid => {
					if (valid) {
						methods.doLogin()
					} else {
						return false
					}
				})
			},
			doLogin: async () => {
				state.loading = true
				try {
					const { username, password } = state.loginForm
					const data = await loginByUsername(appStore.tenantId, username, md5(password))
					if (data && data.error_description) throw new Error(data.error_description)
					userStore.setToken(data.access_token)
					const expireTime = data.expires_in * 1000 + Date.now()
					userStore.setExpire(expireTime)
					userStore.setRefreshToken(data.refresh_token)
					userStore.setUserInfo(data)
					const roleIDList = data.roleId.split(',')
					if (!data.isAdmin) {
						await permissionStore.setAuthList(roleIDList)
					}
					const menuRes = await permissionStore.setMenus()
					// 如果没有，则说明上一步出现异常
					if (!menuRes) return
					// 默认加载第一个子应用的路由
					await permissionStore.setRoutes(menuRes[0].id)
					tagStore.deleteAllTags()
					router.push({
						path: state.redirect || '/',
						query: state.otherQuery,
					})
					tagStore.addTag({
						title: '工作台',
						code: 'dashboard',
						path: '/dashboard',
						isActive: true,
						noCache: false,
						affix: true,
					})
				} catch (err) {
					console.log('获取Token失败 ==>', err)
					$message.error(err.message)
				} finally {
					state.loading = false
				}
			},
		})

		function getOtherQuery(query) {
			return Object.keys(query).reduce((acc, cur) => {
				if (cur !== 'redirect') {
					acc[cur] = query[cur]
				}
				return acc
			}, {})
		}

		watch(
			() => route.query,
			query => {
				if (query) {
					state.redirect = query.redirect?.toString() ?? ''
					state.otherQuery = getOtherQuery(query)
				}
			},
			{ immediate: true },
		)

		onMounted(() => {
			if (state.loginForm.username === '') {
				userNameRef.value.focus()
			} else if (state.loginForm.password === '') {
				passwordRef.value.focus()
			}
			initTenantByDomain()
		})

		return {
			userNameRef,
			passwordRef,
			loginFormRef,
			resetPasswordRef,
			...toRefs(state),
			...toRefs(methods),
		}
	},
})
</script>

<style lang="less">
input:-webkit-autofill {
	box-shadow: 0 0 0 1000px #fff inset;
}
.padding-bottom-40 {
	padding-bottom: 40px;
}
.login-box {
	.el-input__inner {
		padding-left: 50px;
		height: 46px;
		line-height: 46px;
		font-size: 14px;
	}
	.el-form-item {
		position: relative;
		.svg-icon {
			font-size: 20px;
			color: #b0b0b0;
			position: absolute;
			left: 20px;
			top: 13px;
			z-index: 10;
		}
		.eyes {
			left: initial;
			right: 20px;
			cursor: pointer;
			font-size: 22px;
			top: 12px;
		}
	}
	.el-button--primary {
		position: absolute;
		right: 50px;
		bottom: 40px;
		width: 320px;
		height: 46px;
		line-height: 46px;
		font-size: 16px;
		margin: 0;
	}
	.title {
		font-family: PingFangSC-Regular;
		font-weight: 400;
		font-size: 32px;
		color: #333333;
		letter-spacing: 0;
		line-height: 32px;
		margin: 0;
	}
}
</style>
<style lang="less" scoped>
.login-container {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: #f5f5f5;
	.logo {
		width: 160px;
		height: 60px;
		position: absolute;
		top: 40px;
		left: 50px;
		background: url('https://wechatdn.yiautos.com/renrenjia/v1211/logo.png') no-repeat center center;
		background-size: contain;
	}

	.login-dot {
		width: 840px;
		height: 500px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.dot-01 {
			position: absolute;
			top: -128px;
			right: -448px;
			width: 320px;
			height: 278px;
			background: url('https://websitedn.yiautos.com/nrp4/main/bg_login_01.png') no-repeat center
				center;
		}
		.dot-02 {
			position: absolute;
			top: -63px;
			left: -540px;
			width: 371px;
			height: 644px;
			background: url('https://websitedn.yiautos.com/nrp4/main/bg_login_02.png') no-repeat center
				center;
		}
		.dot-03 {
			position: absolute;
			bottom: -229px;
			left: -323px;
			width: 108px;
			height: 94px;
			background: url('https://websitedn.yiautos.com/nrp4/main/bg_login_03.png') no-repeat center
				center;
		}
		.dot-04 {
			position: absolute;
			top: -218px;
			right: -117px;
			width: 108px;
			height: 94px;
			background: url('https://websitedn.yiautos.com/nrp4/main/bg_login_03.png') no-repeat center
				center;
		}
	}
	.login-box {
		display: flex;
		width: 840px;
		height: 500px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 16px;
		overflow: hidden;
	}
	.lb-left {
		width: 420px;
		height: 100%;
		background: url('https://wechatdn.yiautos.com/renrenjia/v0711/login_bg.png') no-repeat center
			center;
		background-size: cover;
	}
	.el-button--text {
		width: 100%;
		justify-content: end;
	}
	.lb-right {
		width: 420px;
		height: 100%;
		padding: 50px 50px 40px;
		background-color: #fff;
	}
}
</style>
